import { Link } from "react-router-dom";
import { constants, routes } from "../../../../../../../constants";
import {
    TransactionAccessType,
    TransactionAccessTypes,
} from "../../../../../../../types/amr-pipeline/enums/TransactionAccessType";
import { TransactionViewHistory } from '../../../../../../../types/amr-pipeline/models/TransactionSession';
import { dateTimeUtils } from "../../../../../../../utils";
import { FormField } from "../../../../../common/FormField";

interface Props {
    transactionReferenceName: string;
    dealReferenceName: string;
    userSession: TransactionViewHistory;
}

export function OverviewTab({
    transactionReferenceName,
    dealReferenceName,
    userSession,
}: Props) {
    const formattedVersionDate = dateTimeUtils.utcToLocalString(
        userSession.versionDate
    );

    return (
        <>
            <FormField
                title="Transaction Version"
                value={
                    <Link
                        to={routes.transactionHistoryUrl(
                            transactionReferenceName,
                            dealReferenceName,
                            "details",
                            userSession.version
                        )}
                    >
                        V.{userSession.version} {formattedVersionDate}
                    </Link>
                }
                className="data-item-row-full-height data-item-row-title-md"
            />
            <FormField
                title="Portfolio Exported"
                value={
                    <>
                        {(userSession.documents &&
                            userSession.documents[
                                TransactionAccessType.ExportTargetPortfolio
                            ])?.length ??
                            constants.emptyPlaceholder}
                    </>
                }
                className="data-item-row-full-height data-item-row-title-md"
            />
            <FormField
                title="Tabs Viewed"
                value={
                    <ul className="tabs-viewed-list">
                        {userSession.views &&
                            TransactionAccessTypes.map((type) => (
                                <li key={type.value} className="tabs-viewed-item">
                                    <span className="tabs-viewed-label">
                                        {type.text}
                                    </span>
                                    {userSession.views[type.value]
                                        ? `${
                                              userSession.views[type.value]
                                          } view(s)`
                                        : constants.emptyPlaceholder}
                                </li>
                            ))}
                    </ul>
                }
                className="data-item-row-full-height data-item-row-title-md"
            />
        </>
    );
}
