import { useEffect } from 'react';
import { getDealRegularDocumentTypes, getDocumentTypeText } from '../../../../../types/amr-pipeline/enums/DocumentType';
import { Deal } from '../../../../../types/amr-pipeline/models/Deal';
import { SearchInput } from '../../../../common';
import { TabBody } from '../../../common/tabs/TabBody';
import { DocumentType } from '../../../../../types/amr-pipeline/enums/DocumentType';
import { useState } from 'react';
import { DocumentSection } from '../../../common/documents/DocumentSection';
import { imDetailsDealsActions } from '../../../../../actions';
import { Transaction } from '../../../../../types/amr-pipeline/models/Transaction';
import { compareDates } from '../../../../../utils/compare.utils';
import { useAppDispatch } from '../../../../../effects/useAppDispatch';
import { roles } from '../../../../../constants';
import { user } from '../../../../../user';
import { amrPipelineService } from '../../../../../services/amr-pipeline.service';
import { dealsService } from '../../../../../services/deals.service';
import { getDealDocLastDate } from '../../../../../utils/amr-pipeline.utils';

interface DocumentsProps {
    deal: Deal,
    transaction: Transaction;
}

export function Documents({ deal, transaction }: DocumentsProps) {
    const dispatch = useAppDispatch();
    const [searchTerm, setSearchTerm] = useState('');
    const isAdminOrDataEntry = user.hasRoles(roles.Administrator, roles.DataEntry);


    useEffect(() => {
        dispatch(imDetailsDealsActions.resetDocumentsSearch());
    }, [dispatch])

    if (deal.documents == null) {
        return null;
    }

    const { documents: { dealDocuments: originalDocuments}, filteredDocuments } = deal;

    const documents = (filteredDocuments
        ? filteredDocuments
        : originalDocuments
    ).filter(a => !isAdminOrDataEntry ? a.isPublic : a)
    .map(d => ({ ...d, executionDate: getDealDocLastDate(d) }))
    .sort((a, b) => compareDates(b.executionDate, a.executionDate));


    const amrDocuments = documents.filter(d => d.transactionReferenceName != null);
    const dealDocuments = documents.filter(d => d.transactionReferenceName == null);
    const dealRegularDocuments = dealDocuments.filter(d => getDealRegularDocumentTypes().some(t => t === d.documentType));
    const distributionReports = dealDocuments.filter(d => d.documentType === DocumentType.distributionReport);
    const monthlyReports = dealDocuments.filter(d => d.documentType === DocumentType.monthlyReport);
    const otherDocuments = dealDocuments.filter(d => d.documentType === DocumentType.otherDocument);

    function handleSearchTermChange(value: string) {
        setSearchTerm(value);
        dispatch(
            imDetailsDealsActions.documentsSearchTermChange(
                value,
                deal.referenceName
            )
        );
    }

    function handleSearchTermClear() {
        setSearchTerm('');
        dispatch(
            imDetailsDealsActions.documentsSearchTermChange('', deal.referenceName)
        );
    }

    return (
        <TabBody className="tabs-data-padding component-tabs-deal-documents">
            <SearchInput
                onClear={handleSearchTermClear}
                value={searchTerm}
                onChange={handleSearchTermChange}
                placeholder="Search for documents"
            />
            <DocumentSection
                title="AMR Documents"
                dealReferenceName={deal.referenceName}
                transactionReferenceName={transaction.referenceName}
                items={amrDocuments}
                emptyPlaceholder={searchTerm.length > 0 ? 'No search results.' : 'There are no documents yet.'}
                downloadAllFetch={amrPipelineService.getTransactionDocuments}
                downloadAllArgs={[deal.referenceName, transaction.referenceName]}
                fileNameForSave={`${deal.ticker} AMR Documents.zip`}
            />
            <DocumentSection
                title="Deal Documents"
                dealReferenceName={deal.referenceName}
                items={dealRegularDocuments}
                emptyPlaceholder={searchTerm.length > 0 ? 'No search results.' : 'There are no documents yet.'}
                downloadAllFetch={dealsService.getDealRegularDocuments}
                downloadAllArgs={[deal.referenceName]}
                fileNameForSave={`${deal.ticker} Deal Documents.zip`}
            />
            <DocumentSection
                title="Distribution Reports"
                dealReferenceName={deal.referenceName}
                items={distributionReports}
                emptyPlaceholder={searchTerm.length > 0 ? 'No search results.' : 'There are no documents yet.'}
                downloadAllFetch={dealsService.getDealDocumentsByType}
                downloadAllArgs={[deal.referenceName, DocumentType.distributionReport]}
                fileNameForSave={`${deal.ticker} Distribution Reports.zip`}
            />
            <DocumentSection
                title="Monthly Reports"
                dealReferenceName={deal.referenceName}
                items={monthlyReports}
                emptyPlaceholder={searchTerm.length > 0 ? 'No search results.' : 'There are no documents yet.'}
                downloadAllFetch={dealsService.getDealDocumentsByType}
                downloadAllArgs={[deal.referenceName, DocumentType.monthlyReport]}
                fileNameForSave={`${deal.ticker} ${getDocumentTypeText(DocumentType.monthlyReport)}.zip`}
            />
            <DocumentSection
                title="Other Documents"
                dealReferenceName={deal.referenceName}
                items={otherDocuments}
                emptyPlaceholder={searchTerm.length > 0 ? 'No search results.' : 'There are no documents yet.'}
                downloadAllFetch={dealsService.getDealDocumentsByType}
                downloadAllArgs={[deal.referenceName, DocumentType.otherDocument]}
                fileNameForSave={`${deal.ticker} ${getDocumentTypeText(DocumentType.otherDocument)}.zip`}
            />
        </TabBody>
    );
}
