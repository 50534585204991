import { OriginatingTransactionDocument } from "../../../../../../../types/amr-pipeline/models/OriginatingTransactionDocument";
import { EmptyPlaceholder } from "../../../../../../common";
import DownloadDocument from "../../../../../common/documents/DownloadDocument";
import { DownloadedDocument } from '../../../../../../../types/amr-pipeline/models/AnalyticsSession';
import useDownloadDocumentList from "../../../../../../../effects/useDownloadDocumentList";
import { amrPipelineService } from "../../../../../../../services/amr-pipeline.service";
import { DownloadedDocumentAccessType } from "../../../../../../../types/amr-pipeline/enums/DownloadedDocumentAccessType";
import { useMemo } from "react";
import { getUniqueDocsWithDownloadsCount } from "../../../../../../../utils/analytics.utils";

interface Props {
    transactionReferenceName: string;
    dealReferenceName: string;
    documents: DownloadedDocument[];
    notDownloadableDocNames: string[];
}

export function DocsDownloadedTab({
    transactionReferenceName,
    dealReferenceName,
    documents,
    notDownloadableDocNames,
}: Props) {
    const uniqueDocuments = useMemo(() => getUniqueDocsWithDownloadsCount(documents), [documents]);

    const { loadHandler, loadingState } = useDownloadDocumentList({
        documents: uniqueDocuments,
        downloadRequest: (referenceName: string, accessType: DownloadedDocumentAccessType) =>
            amrPipelineService.downloadStatisticDocument(
                dealReferenceName,
                transactionReferenceName,
                referenceName,
                accessType,
            ),
    });

    const getDocumentDownloadLink = (document: DownloadedDocument) =>
        loadHandler(document.referenceName, document.name, document.accessType);

    if (!uniqueDocuments.length && !notDownloadableDocNames.length) {
        return <EmptyPlaceholder text="There are no downloaded docs yet." />;
    }

    return (
        <ul className="list-bullet docs-downloaded-box">
            {notDownloadableDocNames?.map((docName, index) => (
                <li key={docName + index} className="download-document-item">
                    {docName}
                </li>
            ))}
            {uniqueDocuments?.map((d) => (
                <li key={d.referenceName} className="download-document-item">
                    <DownloadDocument
                        document={
                            {
                                referenceName: d.referenceName,
                                name: d.name,
                            } as OriginatingTransactionDocument
                        }
                        onClick={() => getDocumentDownloadLink(d)}
                        secondary
                        isLoading={loadingState[d.referenceName]}
                        loadingText
                    >
                        {d.name}
                    </DownloadDocument>
                    {d.downloadCount > 1 && !loadingState[d.referenceName] && ` — ${d.downloadCount} downloads`}
                </li>
            ))}
        </ul>
    );
}
